<template>
  <!-- modal Add Coin-->
  <validation-observer ref="editLoanForm" #default="{ handleSubmit }">
    <b-modal
      :id="`modal-loan-edit${inEdit}`"
      hide-footer
      cancel-variant="outline-secondary"
      centered
      @ok="handleEdit"
      :ok-disabled="false"
      headerClass="px-2 py-2 border-bottom bgWhite"
    >
      <template #modal-header="{ close }">
        <h3 class="m-0">{{ inEdit ? "更改" : "新增" }}借貸記錄</h3>
        <a @click="close()">
          <font-awesome-icon style="font-size: 20px" icon="fas fa-times" />
        </a>
      </template>
      <b-overlay
        v-if="loanDataTemp"
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form class="mt-2" @submit.prevent="handleSubmit(handleEdit)">
          <div class="px-2">
            <b-row class="">
              <!-- contact_name -->
              <!-- <b-col cols="12" md="6" v-if="loanDataTemp.client">
                <b-form-group
                  label="借貸人姓名（中）："
                  label-for="loan_name_cn"
                  label-cols-md="3"
                  :class="isNameRequired ? 'required' : ''"
                >
                  <b-form-input
                    id="loan_name_cn"
                    :disabled="client_cn ? true : false"
                    v-model="loanDataTemp.client.name_cn"
                    placeholder="輸入借貸人姓名（中）"
                  />
                  <div class="error-text" v-if="!isNameRequired">
                    借貸人姓名（中） 或 借貸人姓名（英） 只需輸入其中一項
                  </div>
                </b-form-group>
              </b-col> -->

              <!-- contact_name -->
              <!-- <b-col cols="12" md="6" v-if="loanDataTemp.client">
                <b-form-group
                  label="借貸人姓名（英）："
                  label-for="loan_name_en"
                  label-cols-md="3"
                  :class="isNameRequired ? 'required' : ''"
                >
                  <b-form-input
                    id="loan_name_en"
                    :disabled="client_en ? true : false"
                    v-model="loanDataTemp.client.name_en"
                    placeholder="輸入借貸人姓名（英）"
                  />
                  <div class="error-text" v-if="!isNameRequired">
                    借貸人姓名（中） 或 借貸人姓名（英） 只需輸入其中一項
                  </div>
                </b-form-group>
              </b-col> -->

              <!-- loan_date -->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="貸款日期"
                  rules="required"
                >
                  <b-form-group
                    label="貸款日期："
                    :label-for="`loan_date`"
                    label-cols-md="3"
                    class="required"
                  >
                    <!-- :disabled="inEdit ? true : false" -->
                    <flat-pickr
                      v-model="loanDataTemp.loan_date"
                      class="form-control"
                      :config="config2"
                      placeholder="輸入貸款日期"
                      @input="calcLoanCloseDate"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- amount -->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="貸款本金"
                  rules="required"
                >
                  <b-form-group
                    label="貸款本金："
                    :label-for="`loan_amount`"
                    label-cols-md="3"
                    class="required"
                  >
                    <!-- :disabled="inEdit ? true : false" -->
                    <b-form-input
                      :id="`loan_amount`"
                      v-model="loanDataTemp.amount"
                      type="number"
                      :state="getValidationState(validationContext)"
                      placeholder="輸入貸款本金"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <hr v-if="false" />
              <!-- period -->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="總期數"
                  rules="required"
                >
                  <b-form-group
                    label="總期數："
                    :label-for="`loan_period`"
                    label-cols-md="3"
                    class="required"
                  >
                    <!-- :disabled="inEdit ? true : false" -->
                    <b-form-input
                      :id="`loan_period`"
                      :state="getValidationState(validationContext)"
                      type="number"
                      v-model="loanDataTemp.period"
                      placeholder="輸入總期數"
                      @keyup="calcLoanCloseDate"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- is_monthly -->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="供款週期"
                  rules="required"
                >
                  <b-form-group
                    label="供款週期："
                    :label-for="`loan_is_monthly`"
                    label-cols-md="3"
                    class="required"
                  >
                    <!-- :disabled="inEdit ? true : false" -->
                    <div class="form-col-select">
                      <v-select
                        v-model="loanDataTemp.is_monthly"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="loan_is_monthly"
                        :clearable="false"
                        label="name"
                        :reduce="(option) => option.id"
                        placeholder="輸入供款週期"
                        @input="calcLoanCloseDate"
                      />
                      <feather-icon size="18" icon="ChevronDownIcon" />
                    </div>
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- first_pay_date -->
              <b-col cols="12" md="6">
                <!-- <validation-provider
                  #default="validationContext"
                  name="首次供款日期"
                  rules="required"
                > -->
                <b-form-group
                  label="首次供款日期："
                  :label-for="`loan_first_pay_date`"
                  label-cols-md="3"
                >
                    <!-- class="required" -->
                    <!-- :disabled="inEdit ? true : false" -->
                    <flat-pickr
                      v-model="loanDataTemp.first_pay_date"
                      class="form-control"
                      :config="config"
                      placeholder="輸入首次供款日期"
                    />
                  <!-- <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback> -->
                </b-form-group>
                <!-- </validation-provider> -->
              </b-col>

              <!-- contract_end_date -->
              <b-col cols="12" md="6" v-if="false">
                <!-- <validation-provider
                  #default="validationContext"
                  name="合約完結日"
                  rules="required"
                > -->
                <b-form-group
                  label="合約完結日："
                  :label-for="`loan_contract_end_date`"
                  label-cols-md="3"
                >
                  <!-- class="required" -->

                  <!-- :disabled="inEdit ? true : false" -->
                  <flat-pickr
                    v-model="loanDataTemp.contract_end_date"
                    class="form-control"
                    :config="{
                      altInput: true,
                      altFormat: 'Y-m-d',
                      allowInput: true,
                      dateFormat: 'Y-m-d',
                    }"
                    placeholder="輸入合約完結日"
                  />
                  <!-- <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback> -->
                </b-form-group>
                <!-- </validation-provider> -->
              </b-col>

              <!-- min_paid -->
              <b-col cols="12" md="6" v-if="false">
                <b-form-group
                  label="每月/最低供款："
                  :label-for="`loan_min_paid`"
                  label-cols-md="3"
                >
                  <b-form-input
                    :id="`loan_min_paid`"
                    type="number"
                    v-model="loanDataTemp.min_paid"
                    placeholder="輸入每月/最低供款"
                  />
                </b-form-group>
              </b-col>

              <!-- remain_period-->
              <b-col cols="12" md="6" v-if="false">
                <b-form-group
                  label="尚欠期數："
                  :label-for="`loan_remain_period`"
                  label-cols-md="3"
                >
                  <b-form-input
                    :id="`loan_remain_period`"
                    v-model="loanDataTemp.remain_period"
                    placeholder="輸入尚欠期數"
                  />
                </b-form-group>
              </b-col>
              <hr v-if="false" />

              <!-- contract_number -->
              <b-col cols="12" md="6" v-if="false">
                <b-form-group
                  label="合約編號："
                  :label-for="`loan_contract_number`"
                  label-cols-md="3"
                >
                  <b-form-input
                    :id="`loan_contract_number`"
                    v-model="loanDataTemp.contract_number"
                    placeholder="輸入合約編號"
                  />
                </b-form-group>
              </b-col>

              <!-- application_number -->
              <b-col cols="12" md="6" v-if="false">
                <b-form-group
                  label="申請編號："
                  :label-for="`loan_application_number`"
                  label-cols-md="3"
                >
                  <b-form-input
                    :id="`loan_application_number`"
                    v-model="loanDataTemp.application_number"
                    placeholder="輸入申請編號"
                  />
                </b-form-group>
              </b-col>

              <!-- annual_interest_rate -->
              <b-col cols="12" md="6" v-if="false">
                <b-form-group
                  label="年利率："
                  :label-for="`loan_annual_interest_rate`"
                  label-cols-md="3"
                >
                  <b-form-input
                    :id="`loan_annual_interest_rate`"
                    type="number"
                    :disabled="inEdit ? true : false"
                    v-model="loanDataTemp.annual_interest_rate"
                    placeholder="輸入年利率"
                  />
                </b-form-group>
              </b-col>

              <!-- total_interest -->
              <b-col cols="12" md="6" v-if="false">
                <b-form-group
                  label="總利息："
                  :label-for="`loan_total_interest`"
                  label-cols-md="3"
                >
                  <b-form-input
                    :id="`loan_total_interest`"
                    v-model="loanDataTemp.total_interest"
                    placeholder="輸入總利息"
                  />
                </b-form-group>
              </b-col>
              <hr v-if="false" />

              <!-- loan_status_id -->
              <b-col cols="12" md="6" v-if="false">
                <b-form-group
                  label="合約狀況："
                  :label-for="`loan_status_id`"
                  label-cols-md="3"
                >
                  <div class="form-col-select">
                    <v-select
                      v-model="loanDataTemp.loan_status_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="loanStatusOptions"
                      :clearable="false"
                      label="name"
                      :reduce="(option) => option.id"
                      placeholder="輸入合約狀況"
                      @input="
                        () => {
                          trigger = !trigger;
                        }
                      "
                      :key="`is_monthly${trigger}`"
                    />
                    <feather-icon size="18" icon="ChevronDownIcon" />
                  </div>
                </b-form-group>
              </b-col>

              <!-- admin_status_id -->
              <b-col cols="12" md="6" v-if="false">
                <b-form-group
                  label="狀態："
                  :label-for="`admin_status_id`"
                  label-cols-md="3"
                >
                  <div class="form-col-select">
                    <v-select
                      v-model="loanDataTemp.admin_status_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="adminStatusOptions"
                      :clearable="false"
                      label="name"
                      :reduce="(option) => option.id"
                      placeholder="輸入狀態"
                      @input="
                        () => {
                          trigger = !trigger;
                        }
                      "
                      :key="`is_monthly${trigger}`"
                    />
                    <feather-icon size="18" icon="ChevronDownIcon" />
                  </div>
                </b-form-group>
              </b-col>
              <hr v-if="false" />

              <!-- pay_date -->
              <b-col cols="12" md="6" v-if="false">
                <b-form-group
                  label="供款日："
                  :label-for="`loan_pay_date`"
                  label-cols-md="3"
                >
                  <b-form-input
                    :id="`loan_pay_date`"
                    v-model="loanDataTemp.pay_date"
                    placeholder="輸入供款日"
                  />
                </b-form-group>
              </b-col>

              <!-- remark -->
              <b-col cols="12" md="12" v-if="false">
                <div
                  class="d-flex flex-wrap flex-md-nowrap align-items-center full_input"
                >
                  <label>貸款備註：</label>
                  <div class="input_wrap">
                    <b-form-input
                      id="loan_remark"
                      v-model="loanDataTemp.remark"
                      placeholder="輸入貸款備註"
                    />
                  </div>
                </div>
              </b-col>
              <hr />
            </b-row>
            <b-table
              v-if="loanData.logs && loanData.logs.length > 0"
              ref="refLoanLogsListTable"
              class="mb-1"
              :items="loanData.logs"
              responsive
              :fields="tableColumns"
              show-empty
              empty-text="未找到匹配的記錄"
            >
              <!-- Column: create time -->
              <template #cell(created_at)="data">
                <div class="d-flex">
                  {{ getCorrectDateTime(data.item.created_at) }}
                </div>
              </template>

              <template #cell(creator)="data">
                {{ data.item.creator.contact_name }}
              </template>
              <template #cell(changedData)="data">
                <div
                  class="d-flex logs-flex"
                  v-if="JSON.parse(data.item.changedData).loan_date"
                >
                  <span class="title">貸款日期：</span>
                  <span class="desc"
                    >{{
                      JSON.parse(data.item.changedData).loan_date.old_value
                    }}
                    >
                    {{
                      JSON.parse(data.item.changedData).loan_date.new_value
                    }}</span
                  >
                </div>
                <div
                  class="d-flex logs-flex"
                  v-if="JSON.parse(data.item.changedData).amount"
                >
                  <span class="title">貸款本金：</span>
                  <span class="desc"
                    >{{ JSON.parse(data.item.changedData).amount.old_value }} >
                    {{
                      JSON.parse(data.item.changedData).amount.new_value
                    }}</span
                  >
                </div>
                <div
                  class="d-flex logs-flex"
                  v-if="JSON.parse(data.item.changedData).period"
                >
                  <span class="title">總期數：</span>
                  <span class="desc"
                    >{{ JSON.parse(data.item.changedData).period.old_value }} >
                    {{
                      JSON.parse(data.item.changedData).period.new_value
                    }}</span
                  >
                </div>
                <div
                  class="d-flex logs-flex"
                  v-if="JSON.parse(data.item.changedData).is_monthly"
                >
                  <span class="title">供款週期：</span>
                  <span class="desc"
                    >{{
                      JSON.parse(data.item.changedData).is_monthly.old_value
                        ? "月供"
                        : "半月供"
                    }}
                    >
                    {{
                      JSON.parse(data.item.changedData).is_monthly.new_value
                        ? "月供"
                        : "半月供"
                    }}</span
                  >
                </div>
                <div
                  class="d-flex logs-flex"
                  v-if="JSON.parse(data.item.changedData).first_pay_date"
                >
                  <span class="title">首次供款日期：</span>
                  <span class="desc"
                    >{{
                      JSON.parse(data.item.changedData).first_pay_date.old_value
                    }}
                    >
                    {{
                      JSON.parse(data.item.changedData).first_pay_date.new_value
                    }}</span
                  >
                </div>
              </template>

              <!-- <template #cell(changedData)="data">
                <div
                  class="d-flex logs-flex"
                  v-if="JSON.parse(data.item.changedData).name_cn"
                >
                  <span class="title">name_cn</span>
                  <span class="desc">
                    {{ JSON.parse(data.item.changedData).name_cn.old_value }}
                    >
                    {{
                      JSON.parse(data.item.changedData).name_cn.new_value
                    }}</span
                  >
                </div>
                <div
                  class="d-flex logs-flex"
                  v-if="JSON.parse(data.item.changedData).name_en"
                >
                  <span class="title">name_en</span>
                  <span class="desc">
                    {{ JSON.parse(data.item.changedData).name_en.old_value }}
                    >
                    {{
                      JSON.parse(data.item.changedData).name_en.new_value
                    }}</span
                  >
                </div> -->
              <!-- <div
                  class="d-flex logs-flex"
                  v-if="JSON.parse(data.item.changedData).loan_date"
                >
                  <span class="title">loan_date</span>
                  <span class="desc">
                    {{ JSON.parse(data.item.changedData).loan_date.old_value }}
                    >
                    {{
                      JSON.parse(data.item.changedData).loan_date.new_value
                    }}</span
                  >
                </div>
                <div
                  class="d-flex logs-flex"
                  v-if="JSON.parse(data.item.changedData).amount"
                >
                  <span class="title">amount</span>
                  <span class="desc">
                    {{ JSON.parse(data.item.changedData).amount.old_value }} >
                    {{
                      JSON.parse(data.item.changedData).amount.new_value
                    }}</span
                  >
                </div>
                <div
                  class="d-flex logs-flex"
                  v-if="JSON.parse(data.item.changedData).period"
                >
                  <span class="title">period</span>
                  <span class="desc">
                    {{ JSON.parse(data.item.changedData).period.old_value }} >
                    {{
                      JSON.parse(data.item.changedData).period.new_value
                    }}</span
                  >
                </div>
                <div
                  class="d-flex logs-flex"
                  v-if="JSON.parse(data.item.changedData).is_monthly"
                >
                  <span class="title">is_monthly</span>
                  <span class="desc">
                    {{ JSON.parse(data.item.changedData).is_monthly.old_value }}
                    >
                    {{
                      JSON.parse(data.item.changedData).is_monthly.new_value
                    }}</span
                  >
                </div>
                <div
                  class="d-flex logs-flex"
                  v-if="JSON.parse(data.item.changedData).first_pay_date"
                >
                  <span class="title">first_pay_date</span>
                  <span class="desc">
                    {{
                      JSON.parse(data.item.changedData).first_pay_date.old_value
                    }}
                    >
                    {{
                      JSON.parse(data.item.changedData).first_pay_date.new_value
                    }}</span
                  >
                </div>
              </template> -->
            </b-table>
            <hr v-if="loanData.logs && loanData.logs.length > 0" />
          </div>
          <div class="d-flex flex-wrap m-2 justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-sm-2 mobile-w100"
              type="submit"
              style="line-height: 1.1"
            >
              <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
              提交
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hideModal"
              class="mt-1 mt-sm-0 mobile-w100"
            >
              取消
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </b-modal>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import { required, email } from "@validations";
import { ref, onMounted, onUnmounted } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import loanStoreModule from "../loanStoreModule";
import store from "@/store";
import {
  BModal,
  BButton,
  BCard,
  BRow,
  BCol,
  BTable,
  BFormInput,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BOverlay,
} from "bootstrap-vue";

export default {
  components: {
    BModal,
    BButton,
    BCard,
    BTable,
    BRow,
    BCol,
    BFormInput,
    BForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BOverlay,
    flatPickr,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      trigger: false,
      isInit: true,
      client_cn: "",
      client_en: "",
      loanDataTemp: {},
      required,
      loan_is_monthly: [
        { id: 1, name: "月供" },
        { id: 0, name: "半月供" },
      ],
      isCalc: false,
      tableColumns: [
        // { key: "id", label: "ID "},
        { key: "created_at", label: "修改時間", sortable: true },
        { key: "creator", label: "修改人" },
        { key: "content", label: "修改主題" },
        { key: "changedData", label: "修改內容" },
      ],
      config:{
        altInput: true,
        altFormat: 'Y-m-d',
        allowInput: true,
        dateFormat: 'Y-m-d',
      },
      config2:{
        altInput: true,
        altFormat: 'Y-m-d',
        allowInput: true,
        dateFormat: 'Y-m-d',
        maxDate: this.$store.state.app.maxDate,
      }
    };
  },
  computed: {
    // isNameRequired() {
    //   if (!this.loanDataTemp.client) {
    //     return false;
    //   }
    //   if (
    //     !this.loanDataTemp.client.name_cn &&
    //     !this.loanDataTemp.client.name_en
    //   ) {
    //     return false;
    //   } else {
    //     return true;
    //   }
    // },
  },
  props: {
    loanData: {
      type: Object,
      required: true,
    },
    inEdit: {
      type: Number,
      required: true,
    },
    clientId: {
      type: Number,
    },
  },
  methods: {
    hideModal() {
      this.$root.$emit(
        "bv::hide::modal",
        `modal-loan-edit${this.inEdit}`,
        "#btnShow"
      );
    },
    formatTimeWithoutHMS(time) {
      let newt = new Date(time),
        month,
        day,
        hour,
        min,
        sec;
      month = newt.getMonth() + 1;
      month = month < 10 ? "0" + month : month;
      day = newt.getDate();
      day = day < 10 ? "0" + day : day;
      return `${newt.getFullYear()}-${month}-${day}`;
    },
    handleEdit() {
      var url = null;
      if (this.inEdit) {
        // if (!this.isNameRequired) {
        //   this.loading = false;
        //   return;
        // }
        this.loading = true;
        // delete this.loanDataTemp["annual_interest_rate"];
        // delete this.loanDataTemp["period"];
        // delete this.loanDataTemp["contract_end_date"];
        // delete this.loanDataTemp["first_pay_date"];
        // delete this.loanDataTemp["amount"];
        // delete this.loanDataTemp["loan_date"];
        url = "editLoan";
      } else {
        url = "createLoan";
      }
      // if(this.loanDataTemp.hasOwnProperty('client'))
      //   delete this.loanDataTemp['client']

      if (this.loanDataTemp.hasOwnProperty("supplier"))
        delete this.loanDataTemp["supplier"];

      if (this.loanDataTemp.hasOwnProperty("logs"))
        delete this.loanDataTemp["logs"];

      if (this.loanDataTemp.hasOwnProperty("creator"))
        delete this.loanDataTemp["creator"];

      if (this.loanDataTemp.hasOwnProperty("loan_status"))
        delete this.loanDataTemp["loan_status"];

      if (this.loanDataTemp.hasOwnProperty("admin_status"))
        delete this.loanDataTemp["admin_status"];

      this.$swal({
        title: `你確定嗎？`,
        showCancelButton: true,
        confirmButtonText: "提交",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.value) {
            this.loading = true;
            store
              .dispatch("app-loan/" + url, this.loanDataTemp)
              .then((response) => {
                this.loading = false;
                this.$swal({
                  text: response.data.message,
                  icon: "success",
                  confirmButtonText: "提交",
                  cancelButtonText: "取消",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });
                if (this.inEdit == 0 || this.inEdit == 2) {
                  this.$router.replace("/loans");
                }
                if (this.inEdit == 1) {
                  this.$emit("refetch-data");
                  this.hideModal();
                }
              })
              .catch((error) => {
                this.loading = false;
                const message = error.response.data.message;
                this.$swal({
                  text: JSON.stringify(error.response.data.message),
                  icon: "error",
                  cancelButtonText: "取消",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });
              });
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$swal({
            text: JSON.stringify(error.response.data.message),
            icon: "error",
            confirmButtonText: "取消",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
    calcLoanCloseDate() {
      if (
        this.loanDataTemp.loan_date &&
        this.loanDataTemp.period &&
        (this.loanDataTemp.is_monthly || this.loanDataTemp.is_monthly == 0) &&
        !this.isCalc
      ) {
        const data = {
          loan_date: this.loanDataTemp.loan_date,
          period: this.loanDataTemp.period,
          is_monthly: this.loanDataTemp.is_monthly,
        };
        this.isCalc = true;
        store
          .dispatch("app-loan/getContractEndDate", data)
          .then((response) => {
            this.loanDataTemp.first_pay_date = response.data.data.paid_date[0];
            this.loanDataTemp.contract_end_date =
              response.data.data.contract_end_date;
            this.loanDataTemp.pay_date =
              response.data.data.paid_date.join(" , ");
            this.isCalc = false;
          })
          .catch((error) => {
            this.isCalc = false;
            this.$swal({
              text: JSON.stringify(error.response.data.message),
              icon: "error",
              cancelButtonText: "取消",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              showClass: {
                popup: "animate__animated animate__bounceIn",
              },
              buttonsStyling: false,
            });
          });
      }
    },
  },
  watch: {
    loanData: {
      handler() {
        if (this.inEdit) {
          this.loanDataTemp = { ...this.loanData };
          if (this.inEdit == 2) {
            this.calcLoanCloseDate();
          }
        } else {
          this.loanDataTemp = { client_id: this.clientId, ...this.loanData };
          // if (this.isInit) {
          //   if (this.loanData.client && this.loanData.client.name_cn)
          //     this.client_cn = this.loanData.client.name_cn;

          //   if (this.loanData.client && this.loanData.client.name_en)
          //     this.client_en = this.loanData.client.name_en;
          // }
        }
        if (!this.loanDataTemp.hasOwnProperty("loan_date")) {
          this.loanDataTemp = {
            ...this.loanDataTemp,
            loan_date: this.formatTimeWithoutHMS(new Date()),
          };
        }
        if (!this.loanDataTemp.hasOwnProperty("contract_end_date")) {
          this.loanDataTemp = { ...this.loanDataTemp, contract_end_date: null };
        }

        if (!this.loanDataTemp.hasOwnProperty("loan_pay_date")) {
          this.loanDataTemp = { ...this.loanDataTemp, loan_pay_date: null };
        }

        if (!this.loanDataTemp.hasOwnProperty("first_pay_date")) {
          this.loanDataTemp = { ...this.loanDataTemp, first_pay_date: null };
        }
      },
      deep: true,
    },
  },

  setup() {
    const loan_STORE_MODULE_NAME = "app-loan";

    // Register module
    if (!store.hasModule(loan_STORE_MODULE_NAME))
      store.registerModule(loan_STORE_MODULE_NAME, loanStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(loan_STORE_MODULE_NAME))
        store.unregisterModule(loan_STORE_MODULE_NAME);
    });

    const resetLoanData = () => {
      props.loanData = JSON.parse(JSON.stringify({ id: 0 }));
    };

    const loading = ref(false);

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetLoanData);

    const adminStatusOptions = ref([]);
    const loanStatusOptions = ref([]);

    const refetchOption = () => {
      store
        .dispatch("app/fetchOptionList", {
          admin_status: true,
          loan_status: true,
        })
        .then((response) => {
          adminStatusOptions.value = response.data.admin_statuses;
          loanStatusOptions.value = response.data.loan_statuses;
        });
    };

    onMounted(() => {
      refetchOption();
    });

    return {
      adminStatusOptions,
      loanStatusOptions,
      refetchOption,
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
    };
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
#modal-loan-edit0,
#modal-loan-edit1,
#modal-loan-edit2 {
  .modal-dialog {
    max-width: 1300px;
  }
}
</style>