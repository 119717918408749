<template>
  <div class="p-2 bgCommon">
    <modal-loan-edit
      :loan-data="loanData"
      :inEdit="1"
      @refetch-data="refetchData"
    ></modal-loan-edit>
      <div class="d-flex flex-wrap flex-sm-nowrap mt-2 mb-1 align-items-center justify-content-between">
        <!-- Table Top -->

        <div class="d-flex align-items-center">
          <div>
            <span class="title-size text-nowrap">貸款上傳／更改紀錄</span>
          </div>
          <div class="form-col-select ml-2">
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block"
            />
            <feather-icon size="18" icon="ChevronDownIcon" />
          </div>
        </div>
        <div class="d-flex align-items-center mobile-w100 mt-1 mt-sm-0">
          <div class="search-primary">
            <feather-icon size="16" icon="SearchIcon" />
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="搜尋貸款"
            />
          </div>
        </div>
      </div>
    <b-card class="card-statistics p-1 p-sm-0">
     
      <b-table
        ref="refLoanListTable"
        class="position-relative"
        :items="fetchLoans"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="未找到匹配的記錄"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: create time -->
        <template #cell(created_at)="data">
            {{ getCorrectDateTime(data.item.created_at) }}
        </template>

        <!-- Column: is_monthly -->
        <template #cell(is_monthly)="data">
          {{ data.item.is_monthly ? "月供" : "半月供" }}
        </template>

        <!-- Column: loan_status -->
        <template #cell(loan_status)="data">
          <!-- <a href="javascript:void(0)">{{data.item.loan_status.name}}</a> -->
          {{ data.item.loan_status && data.item.loan_status.name }}
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            :class="`action-trigger-btn ${
              loansList.length <= 3 ? 'hor' : 'ver'
            }`"
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content class="">
              <feather-icon
                icon="EyeIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="ability.can('update', 'loans') || true"
              @click="triggerEditModal(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">修改資料</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="ability.can('delete', 'loan') || true"
              @click="removeItem(data.item)"
            >
              <feather-icon icon="Trash2Icon" />
              <span class="align-middle ml-50">刪除記錄</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >在{{ dataMeta.of }}個記錄中，正在顯示 {{ dataMeta.from }} 至
              {{ dataMeta.to }}個記錄</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalLoans"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
    
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onMounted, onUnmounted } from "@vue/composition-api";
import useLoansList from "./useLoansList";
import loanStoreModule from "../loanStoreModule";
import ModalLoanEdit from "../modal/ModalLoanEdit.vue";

export default {
  components: {
    ModalLoanEdit,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      loanData: {},
      loanFilter: {},
    };
  },
  methods: {
    removeItem(item) {
      // check number of record
      this.$swal({
        title: "你確定嗎?",
        text: "您的決定是最終決定，您將無法取回這記錄。",
        showCancelButton: true,
        confirmButtonText: "刪除",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("app-loan/deleteClientLoan", { id: item.id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    triggerEditModal(item) {
      this.loanData = item;
      this.$bvModal.show(`modal-loan-edit1`);
    },
    clientSearch(options, search) {
      var returnOptions = options.filter((item) => {
        return (
          item.name_cn.toLowerCase().includes(search.toLowerCase()) ||
          item.id_number.toLowerCase().includes(search.toLowerCase())
        );
      });
      return returnOptions;
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-loan";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, loanStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const {
      fetchLoans,
      tableColumns,
      perPage,
      currentPage,
      totalLoans,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refLoanListTable,
      refetchData,
      loansList,
      ability,
      loanStatusOptions,
      loanSupplierOptions,
      clientsOptions,
      refetchOption,
      filter_client_id,
      filter_supplier_id,
      filter_loan_status,
    } = useLoansList();

    return {
      fetchLoans,
      tableColumns,
      perPage,
      currentPage,
      totalLoans,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refLoanListTable,
      refetchData,
      loansList,
      ability,
      loanStatusOptions,
      loanSupplierOptions,
      clientsOptions,
      refetchOption,
      filter_client_id,
      filter_supplier_id,
      filter_loan_status,
    };
  },
};
</script>
    
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.search-primary {
  width: 100%;
}
</style>
    
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/variables/_variables.scss";
</style>
    