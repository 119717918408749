import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchLoans(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/client_loan/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchLoan(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/client_loan/?id=${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getContractEndDate(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/client_loan/getContractEndDate", { params: data})
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    createLoan(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/client_loan/singleStore", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteClientLoan(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/client_loan", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    editLoan(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put("/client_loan", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  }
};
